import { Grow } from "@material-ui/core";
import {
  AttachMoney as MoneyIcon,
  BarChart as TotalSubscriptionsIcon,
  EventAvailable as MonthlySubscriptionsIcon,
} from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { EventTypes } from "../../enums/event-types";
import { ISubscription } from "../../interfaces/subscription";
import dataService from "../../services/data-service";
import eventService from "../../services/event-service";
import MetricItem from "./metric-item";
import SimpleSubscriptionsList from "./simple-subscriptions-list";
import useStyles from "./styles";

function Dashboard() {
  const classes = useStyles();
  const [metrics, setMetrics] = useState({} as any);
  const [weekSubscriptions, setWeekSubscriptions] = useState(
    [] as ISubscription[]
  );
  const [newSubscriptions, setNewSubscriptions] = useState(
    [] as ISubscription[]
  );

  const fetchData = useCallback(async () => {
    await getMetrics();
    await getWeekSubscriptions();
    await getNewSubscriptions();
  }, []);

  async function getMetrics(): Promise<void> {
    const metrics = await dataService.getMetrics();
    setMetrics(metrics);
  }

  async function getWeekSubscriptions(): Promise<void> {
    const weekSubscriptions = await dataService.getWeekSubscriptions();
    setWeekSubscriptions(weekSubscriptions);
  }

  async function getNewSubscriptions(): Promise<void> {
    const newSubscriptions = await dataService.getNewSubscriptions();
    setNewSubscriptions(newSubscriptions);
  }

  useEffect(() => {
    eventService.addListener(EventTypes.SUBSCRIPTION_CREATED, () => {
      fetchData();
    });
    fetchData();

    return () =>
      eventService.removeListener(EventTypes.SUBSCRIPTION_CREATED, () => {});
  }, [fetchData]);

  return (
    <Grow in={true}>
      <div className={classes.dashboard}>
        <div className={classes.metricsArea}>
          <MetricItem label="Renda mensal" value="R$ 0,00" icon={MoneyIcon} />
          <MetricItem
            label="Assinaturas totais"
            value={metrics?.totalSubscriptions || 0}
            icon={TotalSubscriptionsIcon}
          />
          <MetricItem
            label="Assinaturas neste mes"
            value={metrics?.subscriptionsInMonth || 0}
            icon={MonthlySubscriptionsIcon}
          />
        </div>
        <div className={classes.subscriptionsListArea}>
          <SimpleSubscriptionsList
            label="Envios dessa semana"
            subscriptions={weekSubscriptions}
          />
          <SimpleSubscriptionsList
            label="Novas assinaturas"
            subscriptions={newSubscriptions}
          />
        </div>
      </div>
    </Grow>
  );
}

export default Dashboard;
