import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subscriptionActions: {
    width: "100%",
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
  },
  subscriptionStatusUpdateButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    borderRadius: 10,
    padding: 10,
    backgroundColor: "#399AD5",
    color: "#FFF",
    transition: "all 0.3s",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#399AD5",
    },
    marginLeft: 10,
  },
  disabledSubscriptionUpdateButton: {
    backgroundColor: "#ACAAB2",
    color: "#FFF !important",
  },
  leftArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  boxLabel: {
    fontWeight: 700,
  },
  label: {
    fontWeight: 700,
  },
  rightArea: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
  },
  arrowRightIcon: {
    width: 30,
    height: 30,
    color: "#399AD5",
    backgroundColor: "#FFF",
    borderRadius: "100%",
  },
  arrowRightIconDisabled: {
    width: 30,
    height: 30,
    color: "#ACAAB2",
    backgroundColor: "#FFF",
    borderRadius: "100%",
  },
  lastBoxDetailsArea: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: theme.typography.h5.fontSize,
  },
  subscriptionViewField: {
    backgroundColor: "#FFF",
    marginTop: 20,
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    boxShadow: "4px 4px 0px #EBF0F4",
    "& .MuiFilledInput-root": {
      background: "#FFF",
    },
  },
  oldSubscriptionSelectorArea: {
    padding: "18px 18px 0 18px",
  },
  subscriptionStatusChangeArea: {
    display: "flex",
    justifyContent: "space-between",
  },
  subscriptionStatusButton: {
    height: 80,
    width: 255,
    padding: 10,
    backgroundColor: "#FFF",
    borderRadius: 10,
    boxShadow: "4px 4px 0px #399AD5",
  },
}));

export default useStyles;
