import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inventory: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  totalBoxesText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  boxesArea: {
    marginTop: 16,
    display: "flex",
    flexWrap: "wrap",
  },
  monthBoxItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 12,
  },
  boxImage: {},
  boxDetailsArea: {
    marginTop: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  totalContainer: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "100%",
    position: "absolute",
    marginLeft: 50,
    marginTop: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  totalText: {
    fontSize: 24,
    color: "#FFF",
    fontWeight: 700,
  },
  boxMonthText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: theme.typography.h6.fontSize,
  },
  boxTypeText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: theme.typography.body2.fontSize,
  },
  loadingIndicatorArea: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
