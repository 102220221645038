export enum PaymentTypes {
    RECURRENCE = 0,
    YEARLY,
    SEMIANNUAL
  }
  
  export const PaymentTypeLabels = {
    [PaymentTypes.RECURRENCE]: "Recorrência",
    [PaymentTypes.YEARLY]: "Anual",
    [PaymentTypes.SEMIANNUAL]: "Semestral"
  };
  