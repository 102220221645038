import {
  Button,
  Grow,
  IconButton,
  TextField,
  useTheme,
} from "@material-ui/core";
import { Close as CloseIcon, Search as SearchIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { ExportButton, ListActionsProps, useListContext } from "react-admin";
import {
  SubscriptionStatus,
  SubscriptionStatusLabel,
} from "../../../../enums/subscription-status";
import useStyles from "./styles";

function SubscriptionListActions(props: ListActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { setFilters, filterValues } = useListContext();

  const [searchInputValue, setSearchInputValue] = useState("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(
    null as SubscriptionStatus | unknown
  );

  function onInputChange(value: string): void {
    setFilters({ textFilter: value }, "textFilter");
    setSearchInputValue(value);
  }

  function onSelectStatusFilter(statusFilter: SubscriptionStatus): void {
    if (statusFilter === selectedStatusFilter) {
      setSelectedStatusFilter(null);
      setFilters({}, []);
    } else {
      setSelectedStatusFilter(statusFilter);
      setFilters({ statusFilter }, "statusfilter");
    }
  }

  function clearFilters(): void {
    setFilters({}, []);
    setSearchInputValue("");
  }
  return (
    <Grow in>
      <div className={classes.subscriptionListActions}>
        <div className={classes.topArea}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Busque por nome ou pedido"
            value={searchInputValue}
            InputProps={{
              endAdornment: !filterValues.textFilter ? (
                <SearchIcon />
              ) : (
                <IconButton onClick={() => clearFilters()}>
                  <CloseIcon />
                </IconButton>
              ),
              className: classes.searchInput,
            }}
            className={classes.searchInput}
            onChange={(event) => onInputChange(event.target.value)}
          />
          <div>
            <ExportButton />
          </div>
        </div>
        <div className={classes.statusFilterArea}>
          {(
            Object.keys(SubscriptionStatus) as Array<
              keyof typeof SubscriptionStatus
            >
          )
            .filter(
              (subscriptionStatus) =>
                SubscriptionStatusLabel[subscriptionStatus]
            )
            .map((subscriptionStatus) => (
              <Button
                variant="text"
                onClick={() => onSelectStatusFilter(subscriptionStatus as any)}
                className={classes.subscriptionStatusFilterButton}
                style={{
                  color:
                    subscriptionStatus === selectedStatusFilter
                      ? "#399AD5"
                      : theme.palette.text.primary,
                  fontWeight:
                    subscriptionStatus === selectedStatusFilter ? 700 : "",
                }}
              >
                {SubscriptionStatusLabel[subscriptionStatus]}
              </Button>
            ))}
        </div>
      </div>
    </Grow>
  );
}

export default SubscriptionListActions;
