import EventEmitter from "wolfy87-eventemitter";

class EventService {
  static instance: EventService;
  private eventEmmiter: any;
  constructor() {
    if (EventService.instance) {
      return EventService.instance;
    }
    this.eventEmmiter = new EventEmitter();
    EventService.instance = this;
  }

  addListener(eventType: string, func: Function) {
    this.eventEmmiter.addListener(eventType, func);
  }

  removeListener(eventType: string, func: Function) {
    this.eventEmmiter.removeListener(eventType, func);
  }

  emitEvent(eventType: string, params: Array<any> = []) {
    this.eventEmmiter.emitEvent(eventType, params);
  }
}

export default new EventService();
