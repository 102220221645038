import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subscriptionListActions: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: 40,
  },
  topArea: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    borderRadius: 30,
    marginRight: 8,
  },
  statusFilterArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 40,
  },
  subscriptionStatusFilterButton: {
    marginRight: 40,
    textTransform: "none",
  },
}));

export default useStyles;
