import React from "react";
import { Admin, Resource } from "react-admin";
import CustomLayout from "./components/custom-layout";
import Login from "./components/login";
import resources from "./react-admin/resources";
import theme from "./react-admin/theme";
import authService from "./services/auth-service";
import dataService from "./services/data-service";
import Dashboard from "./components/dashboard";
import i18nProvider from "./react-admin/i18n";

function App() {
  return (
    <Admin
      theme={theme}
      //@ts-expect-error
      dataProvider={dataService}
      //@ts-expect-error
      authProvider={authService}
      layout={CustomLayout}
      loginPage={Login}
      // @ts-ignore
      dashboard={Dashboard}
      locale="pt-BR"
      i18nProvider={i18nProvider}
    >
      {resources.map((resource, index) => (
        <Resource
          name={resource.name}
          options={{ label: resource.label }}
          icon={resource.icon}
          list={resource.listComponent}
          show={resource.viewComponent}
          key={index}
        />
      ))}
    </Admin>
  );
}

export default App;
