import React from "react";
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  Record,
  ReferenceField,
  TextField,
} from "react-admin";
import {
  SubscriptionStatus,
  SubscriptionStatusLabel,
} from "../../../enums/subscription-status";
import {
  formatDate,
  getSubscriptionTypeLabel,
} from "../../../helpers/field-format-helper";
import { ISubscription } from "../../../interfaces/subscription";
import SubscriptionListActions from "./subscription-list-actions";

function SubscriptionsList(props: ListProps) {
  function getCurrentBoxLabel(currentBoxMonth: number): string {
    if (currentBoxMonth) return `Box ${currentBoxMonth}`;
    return "-";
  }

  function getSubscriptionStatusLabel(subscription: ISubscription): string {
    //@ts-expect-error
    return SubscriptionStatusLabel[SubscriptionStatus[subscription.status]];
  }

  return (
    <List
      {...props}
      actions={<SubscriptionListActions />}
      sort={{ field: "createdAt", order: "DESC" }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        <ReferenceField
          label="cpf"
          source="customerId"
          reference="customers"
          linkType={false}
          sortable={false}
        >
          <TextField source="cpf" />
        </ReferenceField>
        <ReferenceField
          label="client"
          source="customerId"
          reference="customers"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="subscription"
          render={(record: Record | ISubscription | undefined) =>
            getSubscriptionTypeLabel(record?.type)
          }
        />
        <FunctionField
          label="lastBox"
          render={(record: Record | ISubscription | undefined) =>
            getCurrentBoxLabel(record?.lastBox)
          }
        />
        <FunctionField
          label="lastBoxSentAt"
          render={(record: Record | ISubscription | undefined) =>
            formatDate(record?.lastBoxSentAt)
          }
        />
        <FunctionField
          label="status"
          render={(record: Record | ISubscription | undefined) =>
            getSubscriptionStatusLabel(record as ISubscription)
          }
        />
      </Datagrid>
    </List>
  );
}

export default SubscriptionsList;
