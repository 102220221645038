import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  metricItem: {
    width: "25vw",
    height: 60,
    borderRadius: 30,
    boxShadow: "4px 4px 0px #96D6F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
  },
  iconArea: {
    width: 60,
    height: 60,
    borderRadius: "100%",
    backgroundColor: "#399AD5",
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detailsArea: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 6,
    paddingRight: 30,
    alignItems: "flex-end",
  },
  labelText: {
    color: "#ACAAB2",
    fontFamily: "Dosis, sans-serif",
    fontWeight: 700,
  },
  valueText: {
    color: "#7C8999",
    fontFamily: "Dosis, sans-serif",
    fontWeight: 700,
  },
}));

export default useStyles;
