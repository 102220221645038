import { Button, Dialog, IconButton, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React from "react";
import { ISubscription } from "../../../../interfaces/subscription";
import dataService from "../../../../services/data-service";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";

interface IDeleteSubscriptionModalProps {
  open: boolean;
  onClose: Function;
  subscription: ISubscription;
}

function DeleteSubscriptionModal(props: IDeleteSubscriptionModalProps) {
  const classes = useStyles();
  const history = useHistory();

  async function deleteSubscription(
    subscription: ISubscription
  ): Promise<void> {
    try {
      await dataService.delete("subscriptions", { id: subscription?.id || "" });
      props.onClose();
      history.push("/subscriptions");
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      className={classes.deleteSubscriptionModal}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 40,
        },
      }}
    >
      <div className={classes.dialogContent}>
        <div className={classes.topArea}>
          <IconButton
            onClick={() => props.onClose()}
            className={classes.closeModalButton}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.contentArea}>
          <div className={classes.titleArea}>
            <Typography variant="h4" className={classes.titleText}>
              Essa ação não pode ser desfeita!
            </Typography>
            <Typography variant="h6" className={classes.subtitleText}>
              Você tem certeza que deseja cancelar essa assinatura?
            </Typography>
          </div>
          <div className={classes.buttonsArea}>
            <Button
              className={classes.goBackButton}
              onClick={() => props.onClose()}
            >
              Voltar ao Framework
            </Button>
            <Button
              className={classes.deleteSubscriptionButton}
              onClick={() => deleteSubscription(props.subscription)}
            >
              Cancelar Assinatura
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteSubscriptionModal;
