import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  simpleSubscriptionList: {
    display: "flex",
    flexDirection: "column",
    width: "40vw",
  },
  titleText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  subscriptionsTable: {
    marginTop: 20,
  },
  subscriptionNumberText: {
    color: "#399AD5",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default useStyles;
