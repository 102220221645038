import { FieldType } from "../enums/field-types.enum";
import { SortType } from "../enums/sort-types.enum";

function getFieldType(field: string): string {
  switch (field) {
    case "subscriptionNumber":
      return FieldType.NUMBER;
    default:
      return FieldType.STRING;
  }
}

function sortData(data: any[], field: string, sortType: string): any[] {
  let sortedData = [...data];
  const fieldType = getFieldType(field);
  const customerFields = ["name"];

  if (fieldType === FieldType.STRING) {
    if (sortType === SortType.ASC) {
      sortedData = sortedData.sort((a, b) =>
        customerFields.includes(field)
          ? a["customer"][field].localeCompare(b["customer"][field])
          : a[field].localeCompare(b[field])
      );
    } else {
      sortedData = sortedData.sort((a, b) =>
        customerFields.includes(field)
          ? b["customer"][field].localeCompare(a["customer"][field])
          : b[field].localeCompare(a[field])
      );
    }
  }

  if (fieldType === FieldType.NUMBER) {
    if (sortType === SortType.ASC) {
      sortedData = sortedData.sort((a, b) => a[field] - b[field]);
    } else {
      sortedData = sortedData.sort((a, b) => b[field] - a[field]);
    }
  }

  return sortedData;
}

export { sortData };
