import Amplify from "aws-amplify";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import IUser from "../../interfaces/user";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
});

class AuthService {
  public async login({ username, password }: any): Promise<void> {
    try {
      const user: CognitoUser = await Auth.signIn(username, password);
      this.saveAuthenticationToken(user);
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async checkAuth(): Promise<boolean> {
    try {
      const authenticatedUser: CognitoUser =
        await Auth.currentAuthenticatedUser();
      if (!authenticatedUser) throw new Error("Unauthenticated");

      return Boolean(
        authenticatedUser.getSignInUserSession()?.getIdToken().getJwtToken()
      );
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async logout(): Promise<void> {
    this.removeAuthenticationToken();
    await Auth.signOut();
  }

  public async getUserToken(): Promise<string> {
    const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();

    if (!currentUser) return "";

    return currentUser.getSignInUserSession()?.getIdToken().getJwtToken() || "";
  }

  public async getPermissions(): Promise<any> {
    return Promise.resolve();
  }

  public async getCurrentUserInfo(): Promise<IUser> {
    return await Auth.currentUserInfo();
  }

  private saveAuthenticationToken(user: CognitoUser): void {
    localStorage.setItem(
      "authentication",
      user.getSignInUserSession()?.getIdToken().getJwtToken() || ""
    );
  }

  private removeAuthenticationToken(): void {
    localStorage.removeItem("authentication");
  }
}

export default new AuthService();
