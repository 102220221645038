import { format } from "date-fns";
import {
  SubscriptionTypes,
  SubscriptionTypesLabel,
} from "../enums/suscription-types";

function formatDate(date: string | undefined): string {
  if (!date) return "-";
  return format(new Date(date), "dd/MM/yyyy");
}

function getSubscriptionTypeLabel(type: SubscriptionTypes): string {
  //@ts-expect-error
  return SubscriptionTypesLabel[SubscriptionTypes[type]];
}

export { formatDate, getSubscriptionTypeLabel };
