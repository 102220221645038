import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subscriptionView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 40,
  },
  boxListArea: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  leftArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    marginRight: 10,
  },
  subscriptionDetailsArea: {
    flex: 1,
    display: "flex",
    marginTop: 40,
  },
  customerAddressContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 60,
  },
  customerInfoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    marginRight: 10,
  },
  customerInfoContainerTitleText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  subscriptionViewField: {
    backgroundColor: "#FFF",
    marginTop: 20,
    borderRadius: 10,
    boxShadow: "4px 4px 0px #EBF0F4",
    "& .MuiFilledInput-root": {
      background: "#FFF",
    },
  },
  inputText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: theme.typography.h5.fontSize,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  subscriptionArea: {
    display: "flex",
    flexDirection: "column",
  },
  actionButtonsArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  enableEditingButton: {
    width: 235,
    backgroundColor: "#57BEBF !important",
    color: "#FFF !important",
  },
  cancelButton: {
    width: 155,
    color: "#57BEBF",
    backgroundColor: "#FFF !important",
  },
  saveButton: {
    width: 155,
    backgroundColor: "#57BEBF !important",
    color: "#FFF",
    marginLeft: 10,
  },
  deleteSubscriptionButton: {
    width: 248,
    backgroundColor: `#7C8999 !important`,
    color: "#FFF",
    marginRight: 10,
  },
  progressIndicatorArea: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  oldBoxText: {
    position: "absolute",
    marginTop: -24,
    color: theme.palette.secondary.main,
    fontWeight: 700,
  },
  subscriptionNotesText: {
    padding: 16,
  },
  activedAtDateField: {},
}));

export default useStyles;
