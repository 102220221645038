import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { useRefresh } from "react-admin";
import { SubscriptionStatus } from "../../../../../enums/subscription-status";
import { ISubscription } from "../../../../../interfaces/subscription";
import dataService from "../../../../../services/data-service";
import useStyles from "./styles";

interface IEmailFeedbackModalProps {
  open: boolean;
  onClose: Function;
  status: SubscriptionStatus;
  subscription: ISubscription;
  subscriptionChanged: Function;
}

function EmailFeedbackModal(props: IEmailFeedbackModalProps) {
  const classes = useStyles();
  const refresh = useRefresh();

  const [trackingCodeUrl, setTrackingCodeUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function getModalTitle(status: SubscriptionStatus): string {
    switch (status) {
      case SubscriptionStatus.SENT:
        return "Essa ação envia um e-mail com link de rastreio!";
      case SubscriptionStatus.COMPLETED:
        return "Essa ação envia uma pesquisa de satisfação!";
      default:
        return "Essa ação envia um e-mail!";
    }
  }

  function getModalSubtitle(status: SubscriptionStatus): string {
    return "O cliente receberá um e-mail sendo informado da alteração de status do pedido, você também pode apenas alterar no framework.";
  }

  function getEmailContent(
    status: SubscriptionStatus,
    subscription: ISubscription
  ) {
    switch (status) {
      case SubscriptionStatus.SENT:
        return getSentBoxEmailContent(status, subscription);
      case SubscriptionStatus.COMPLETED:
        return getCompletedBoxEmailContent(status, subscription);
      case SubscriptionStatus.PAYMENT_REJECTED:
        return getPaymentNotApprovedEmailContent(status, subscription);
      case SubscriptionStatus.IN_PROGRESS:
        return getPaymentApprovedEmailContent(status, subscription);
      case SubscriptionStatus.FIRST_PAYMENT_NOT_RECEIVED:
        return getFirstPaymentNotFoundEmailContent(subscription);
    }
  }

  function getSentBoxEmailContent(
    stautus: SubscriptionStatus,
    subscription: ISubscription
  ) {
    return (
      <div>
        <Typography className={classes.emailText}>
          "Olá, {subscription?.customer?.name}!
        </Typography>
        <Typography className={classes.emailText}>
          A Box do(a) {subscription?.customer?.babyName} já está a caminho!
        </Typography>
        <Typography className={classes.emailText}>
          Você pode acompanhar a entrega através deste link:
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Link de rastreio"
          className={classes.trackingCodeInput}
          value={trackingCodeUrl}
          onChange={(event) => setTrackingCodeUrl(event.target.value)}
        />
        <Typography className={classes.emailText}>
          Vamos adorar ver as brincadeiras de vocês com nossos brinquedos e
          saber o que acharam.
        </Typography>
        <Typography className={classes.emailText}>Conta pra gente?</Typography>
        <Typography className={classes.emailText}>Até jajá! “</Typography>
      </div>
    );
  }

  function getCompletedBoxEmailContent(
    stautus: SubscriptionStatus,
    subscription: ISubscription
  ) {
    return (
      <div>
        <Typography className={classes.emailText}>
          "Olá, {subscription?.customer?.name}!
        </Typography>
        <Typography className={classes.emailText}>
          Sua opinião é muito importante para nós, por isso gostaríamos de saber
          o que achou da sua experiência conosco em relação à sua última Box
          recebida!
        </Typography>
        <Typography className={classes.emailText}>
          Ficaremos muito gratas em ter dois minutinhos da sua atenção na
          resposta de nossa pesquisa de satisfação através do link abaixo:
        </Typography>
        <Typography className={classes.emailText}>
          {process.env.REACT_APP_SATISFACTION_FORM_URL}
        </Typography>
        <Typography className={classes.emailText}>
          Muito obrigada, nos vemos na próxima Box!”
        </Typography>
      </div>
    );
  }

  function getPaymentNotApprovedEmailContent(
    status: SubscriptionStatus,
    subscription: ISubscription
  ) {
    return (
      <div>
        <Typography className={classes.emailText}>
          "Olá, {subscription?.customer?.name}!
        </Typography>
        <Typography className={classes.emailText}>
          Recebemos a recusa do seu pagamento e por isso a Box do(a){" "}
          {subscription?.customer?.babyName} precisa da sua ajuda para chegar
          aí!
        </Typography>
        <Typography className={classes.emailText}>
          Pedimos que entre em sua área logada no PagSeguro ou Mercado Pago para
          atualizar os dados do seu cartão de crédito para que possamos dar
          seguimento ao envio de vocês.
        </Typography>
        <Typography className={classes.emailText}>
          Caso você não lembre em qual plataforma cadastrou seu pagamento ou
          precise da nossa ajuda, é só responder a esse e-mail!{" "}
        </Typography>
        <Typography className={classes.emailText}>Até jajá!"</Typography>
      </div>
    );
  }

  function getPaymentApprovedEmailContent(
    status: SubscriptionStatus,
    subscription: ISubscription
  ) {
    return (
      <div>
        <Typography className={classes.emailText}>
          "Olá, {subscription?.customer?.name}!
        </Typography>
        <Typography className={classes.emailText}>
          Recebemos o seu pagamento e já estamos preparando a Box do(a){" "}
          {subscription?.customer?.babyName}!
        </Typography>
        <Typography className={classes.emailText}>
          Em breve você receberá o código de rastreio para acompanhar o caminho
          da Box até vocês!
        </Typography>
        <Typography className={classes.emailText}>Até jajá!"</Typography>
      </div>
    );
  }

  function getFirstPaymentNotFoundEmailContent(subscription: ISubscription) {
    return (
      <div>
        <Typography className={classes.emailText}>
          "Olá, {subscription?.customer?.name}!
        </Typography>
        <Typography className={classes.emailText}>
          Recebemos a sua solicitação de assinatura para o(a){" "}
          {subscription?.customer?.babyName}, mas parece que o pagamento foi
          recusado ou você não conseguiu concluir, né?
        </Typography>
        <Typography className={classes.emailText}>
          Para que você não precise voltar a responder o formulário, é só clicar
          abaixo para concluir o pedido da sua assinatura e começar a curta
          espera para que nossa Box chegue levando diversão e os estímulos que
          o(a) {subscription?.customer?.babyName} precisa nesse momento!
        </Typography>
        <div className={classes.emailButtonArea}>
          <Button className={classes.paymentButton}>Ir para pagamento</Button>
        </div>
        <Typography className={classes.emailText}>Até jajá!"</Typography>
      </div>
    );
  }

  async function updateSubscriptionStatus(
    subscriptionId: string,
    status: SubscriptionStatus,
    sendEmail: boolean
  ): Promise<void> {
    setIsLoading(true);
    try {
      await dataService.updateSubscriptionStatus(
        subscriptionId,
        status,
        sendEmail,
        {
          trackingCodeUrl,
          satisfactionFormUrl: process.env.REACT_APP_SATISFACTION_FORM_URL,
        }
      );
      setIsLoading(false);
      refresh();
      props.subscriptionChanged("status", status);
      props.onClose();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 40,
        },
      }}
    >
      <div className={classes.emailFeedbackModalContent}>
        <div className={classes.topArea}>
          <IconButton onClick={() => props.onClose()}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.contentArea}>
          <Typography variant="h4" className={classes.titleText}>
            {getModalTitle(props.status)}
          </Typography>
          <Typography variant="h6">{getModalSubtitle(props.status)}</Typography>
          <div className={classes.emailContentArea}>
            {getEmailContent(props.status, props.subscription)}
          </div>
          <div className={classes.actionButtonsArea}>
            <Button
              variant="contained"
              className={classes.confirmWithoutEmailButton}
              disabled={isLoading}
              onClick={() =>
                updateSubscriptionStatus(
                  `${props.subscription.id}`,
                  props.status,
                  false
                )
              }
            >
              Confirmar sem e-mail
            </Button>
            <Button
              variant="contained"
              className={classes.confirmAndSendEmailButton}
              disabled={isLoading}
              onClick={() =>
                updateSubscriptionStatus(
                  `${props.subscription.id}`,
                  props.status,
                  true
                )
              }
            >
              Confirmar e enviar e-mail
              {isLoading ? (
                <CircularProgress
                  size={22}
                  className={classes.buttonLoadingIndicator}
                />
              ) : null}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default EmailFeedbackModal;
