import { makeStyles } from "@material-ui/core";
import loginBackgroundImage from "../../assets/images/login-background.png";
import theme from "../../react-admin/theme";

const useStyles = makeStyles(() => ({
  loginPage: {
    flex: 1,
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: `url(${loginBackgroundImage})`,
    backgroundSize: "cover",
  },

  loginFormContainer: {
    padding: 100,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F8F9F9",
    borderRadius: 20,
  },
  logo: {
    width: 115,
    height: 167,
  },
  loginFormArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 30,
  },
  textInput: {
    borderRadius: 20,
    width: 285,
    marginTop: 10,
  },
  loginButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFF !important",
    borderRadius: 40,
    marginTop: 40,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  loginButtonIcon: {
    marginLeft: 10,
    color: "#FFF !important",
  },
}));

export default useStyles;
