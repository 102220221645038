import React from "react";
import useStyles from "./styles";
import { ISubscription } from "../../../interfaces/subscription";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  formatDate,
  getSubscriptionTypeLabel,
} from "../../../helpers/field-format-helper";
import { addMonths } from "date-fns/esm";
import { useHistory } from "react-router-dom";

interface ISimpleSubscriptionsListProps {
  subscriptions: ISubscription[];
  label: string;
}

function SimpleSubscriptionsList(props: ISimpleSubscriptionsListProps) {
  const classes = useStyles();
  const history = useHistory();

  function getSubscriptionSendDate(subscription: ISubscription): string {
    if (subscription.lastBoxSentAt)
      return formatDate(
        addMonths(new Date(subscription.lastBoxSentAt), 1).toISOString()
      );
    return formatDate(subscription.createdAt);
  }

  function navigateToSubscriptionPage(subscription: ISubscription): void {
    history.push(`/subscriptions/${subscription.id}/show`, {
      ...subscription,
    });
  }
  return (
    <div className={classes.simpleSubscriptionList}>
      <Typography variant="h6" className={classes.titleText}>
        {props.label}
      </Typography>
      <TableContainer component={Paper} className={classes.subscriptionsTable}>
        <Table>
          <TableHead>
            <TableCell>Pedido</TableCell>
            <TableCell>Box</TableCell>
            <TableCell>Assinatura</TableCell>
            <TableCell>Data para Envio</TableCell>
          </TableHead>
          <TableBody>
            {props.subscriptions.map((subscription, index) => (
              <TableRow key={index}>
                <TableCell
                  onClick={() => navigateToSubscriptionPage(subscription)}
                >
                  <Typography className={classes.subscriptionNumberText}>
                    {subscription.subscriptionNumber}
                  </Typography>
                </TableCell>
                <TableCell>Box {subscription.currentBox}</TableCell>
                <TableCell>
                  {getSubscriptionTypeLabel(subscription.type)}
                </TableCell>
                <TableCell>{getSubscriptionSendDate(subscription)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SimpleSubscriptionsList;
