import { makeStyles } from "@material-ui/core";
import navbarBackground from "../../../assets/images/navbar-background.png";

const useStyles = makeStyles((theme) => ({
  navbar: {
    height: 80,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `url(${navbarBackground})`,
    display: "flex",
  },
  navbarContent: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftArea: {},
  menu: {
    marginTop: 32,
    width: 220,
  },
  menuButton: {
    width: 50,
    height: 50,
    backgroundColor: "#FFF",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  rightArea: {
    display: "flex",
    alignItems: "center",
  },
  reloadButton: {
    width: 50,
    height: 50,
    backgroundColor: "#FFF",
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  activeUserContainer: {
    height: 30,
    width: 220,
    padding: 10,
    borderTopLeftRadius: 35,
    borderBottomLeftRadius: 35,
    backgroundColor: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  activeUserContainerLeftArea: {
    display: "flex",
    alignItems: "center",
  },
  userImageContainer: {
    width: 30,
    height: 30,
    borderRadius: "100%",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userNameText: {
    marginLeft: 10,
    fontFamily: "Dosis",
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  newSubscriptionsBadge: {
    backgroundColor: "#FF5316",
    color: "#FFF",
  },
}));

export default useStyles;
