import {
  AppBar,
  Badge,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Person as UserIcon,
  Replay as ReloadIcon,
  PowerSettingsNew as LogoffIcon,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { AppBarProps, useLogout, useRefresh } from "react-admin";
import { useHistory } from "react-router-dom";
import { EventTypes } from "../../../enums/event-types";
import { WebSocketMessage } from "../../../enums/websocket-messages-enum";
import IUser from "../../../interfaces/user";
import authService from "../../../services/auth-service";
import eventService from "../../../services/event-service";
import useStyles from "./styles";

function Navbar(props: AppBarProps) {
  const classes = useStyles();
  const history = useHistory();
  const logout = useLogout();
  const refresh = useRefresh();

  const [user, setUser] = useState({} as IUser);
  const [newSubscriptions, setNewSubscriptions] = useState(0);

  useEffect(() => {
    const websocketConnection = new WebSocket(
      "wss://43j4m3ehki.execute-api.us-east-1.amazonaws.com/production",
      "protocolOne"
    );
    websocketConnection.onmessage = (event) => {
      if (event.data === WebSocketMessage.SUBSCRIPTION_CREATED) {
        eventService.emitEvent(EventTypes.SUBSCRIPTION_CREATED);
        refresh();
        setNewSubscriptions(newSubscriptions + 1);
      }
    };
    return () => {
      websocketConnection.close();
    };
  }, []);

  useEffect(() => {
    async function getUserInfo(): Promise<void> {
      const user = await authService.getCurrentUserInfo();
      setUser(user);
    }
    getUserInfo();
  }, []);

  async function handleLogout(): Promise<void> {
    logout();
  }

  function handleRefresh(): void {
    if (newSubscriptions) {
      history.push("/subscriptions");
      setNewSubscriptions(0);
    } else {
      refresh();
    }
  }

  return (
    <AppBar className={classes.navbar} elevation={0}>
      <div className={classes.navbarContent}>
        <div className={classes.leftArea}></div>
        <div className={classes.rightArea}>
          <Tooltip title={newSubscriptions ? "Novas assinaturas" : ""}>
            <Badge
              classes={{
                badge: classes.newSubscriptionsBadge,
              }}
              overlap="circular"
              badgeContent={newSubscriptions}
            >
              <IconButton
                className={classes.reloadButton}
                onClick={handleRefresh}
              >
                <ReloadIcon />
              </IconButton>
            </Badge>
          </Tooltip>
          <div className={classes.activeUserContainer}>
            <div className={classes.activeUserContainerLeftArea}>
              <div className={classes.userImageContainer}>
                <UserIcon />
              </div>
              <Typography className={classes.userNameText}>
                {user?.attributes?.name}
              </Typography>
            </div>
            <Tooltip title="Sair">
              <IconButton onClick={handleLogout}>
                <LogoffIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </AppBar>
  );
}

export default Navbar;
