import { Button, CircularProgress, TextField } from "@material-ui/core";
import { ArrowForward as ArrowRightIcon } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import logo from "../../assets/images/logo.png";
import authService from "../../services/auth-service";
import useStyles from "./styles";

interface ILoginProps {
  history: any;
}
interface ILogin {
  email: string;
  password: string;
}

function Login({ history }: ILoginProps) {
  const classes = useStyles();

  const loginFormInitialValues = {
    email: "",
    password: "",
  };

  const loginFormSchema = Yup.object().shape({
    email: Yup.string()
      .required("Preencha o e-mail.")
      .email("Digite um e-mail valido."),
    password: Yup.string().required("Preencha a senha."),
  });

  async function login(
    values: ILogin,
    setSubmitting: Function,
    setFieldError: Function
  ): Promise<void> {
    setSubmitting(true);
    try {
      await authService.login({
        username: values.email,
        password: values.password,
      });
      await authService.getUserToken();
      setSubmitting(false);
      history.push("/");
    } catch (error: any) {
      setFieldError("password", "E-mail ou senha incorretos.");
      setSubmitting(false);
    }
  }

  return (
    <div className={classes.loginPage}>
      <div className={classes.loginFormContainer}>
        <img src={logo} className={classes.logo} alt="logo" />
        <Formik
          initialValues={loginFormInitialValues}
          validationSchema={loginFormSchema}
          onSubmit={async (values, { setSubmitting, setFieldError }) =>
            await login(values, setSubmitting, setFieldError)
          }
          validateOnChange={false}
          validateOnBlur={true}
          isInitialValid={false}
        >
          {({ submitForm, errors, values, setFieldValue, isSubmitting }) => {
            function onInputChange(field: string, value: string): void {
              setFieldValue(field, value);
            }
            return (
              <Form className={classes.loginFormArea}>
                <TextField
                  placeholder="Usuário"
                  variant="outlined"
                  className={classes.textInput}
                  value={values.email}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={(event) =>
                    onInputChange("email", event.target.value)
                  }
                  InputProps={{
                    className: classes.textInput,
                  }}
                />
                <TextField
                  placeholder="Senha"
                  type="password"
                  variant="outlined"
                  value={values.password}
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                  onChange={(event) =>
                    onInputChange("password", event.target.value)
                  }
                  onKeyPress={(event) =>
                    event.key === "Enter" ? submitForm() : null
                  }
                  InputProps={{
                    className: classes.textInput,
                  }}
                />
                <Button
                  className={classes.loginButton}
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Entrar
                  {!isSubmitting ? (
                    <ArrowRightIcon className={classes.loginButtonIcon} />
                  ) : (
                    <CircularProgress
                      size={22}
                      className={classes.loginButtonIcon}
                    />
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
