// @ts-expect-error
import portugueseMessages from "ra-language-portuguese";
const customMessages = {
  resources: {
    subscriptions: {
      name: "Assinaturas",
      fields: {
        subscriptionNumber: "Pedido",
      },
      empty: "Nenhuma assinatura ainda :(",
    },
    settings: {
      name: "Configurações",
    },
    dashboard: {
      name: "Dashboard",
    },
  },
  client: "Cliente",
  subscription: "Assinatura",
  lastBox: "Ultima Box",
  lastBoxSentAt: "Data de envio",
  status: "Status",
  cpf: "CPF",
  ra: {
    ...portugueseMessages.ra,
    page: {
      ...portugueseMessages.ra.page,
      dashboard: "Dashboard",
    },
  },
};

const translationPtBr = {
  ...portugueseMessages,
  ...customMessages,
};

export default translationPtBr;
