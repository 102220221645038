enum SubscriptionStatus {
  PENDING_PAYMENT = 0,
  PAYMENT_APPROVED,
  PAYMENT_REJECTED,
  IN_PROGRESS,
  SENT,
  COMPLETED,
  CANCELED,
  FIRST_PAYMENT_NOT_RECEIVED,
  CREATE_FISCAL_NOTE,
}

enum SubscriptionStatusLabel {
  PENDING_PAYMENT = "Pagamento Pendente",
  PAYMENT_APPROVED = "Pagamento Aprovado",
  PAYMENT_REJECTED = "Não aprovado",
  IN_PROGRESS = "Em andamento",
  SENT = "Enviada",
  COMPLETED = "Finalizada",
  CANCELED = "Cancelada",
  FIRST_PAYMENT_NOT_RECEIVED = "Primeiro pagamento não recebido",
  CREATE_FISCAL_NOTE = "Emitir nota fiscal",
}

export { SubscriptionStatus, SubscriptionStatusLabel };
