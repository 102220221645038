import React from "react";
import { Layout, LayoutProps } from "react-admin";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import useStyles from "./styles";

function CustomLayout(props: LayoutProps) {
  const classes = useStyles();
  return (
    <Layout
      {...props}
      appBar={Navbar}
      // @ts-expect-error
      sidebar={Sidebar}
      classes={{
        contentWithSidebar: classes.content,
      }}
    />
  );
}

export default CustomLayout;
