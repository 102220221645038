import { FormControlLabel, Grow, Paper, Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useUpdate } from "react-admin";
import { ISetting } from "../../interfaces/setting";
import dataService from "../../services/data-service";
import useStyles from "./styles";

function Settings() {
  const classes = useStyles();
  const [update] = useUpdate();
  const [settings, setSettings] = useState([] as ISetting[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getSettings(): Promise<void> {
      setIsLoading(true);
      const settings = await dataService.getSettings();
      setSettings(settings);
      setIsLoading(false);
    }
    getSettings();
  }, []);

  function updateNewSubscriptionsMetric(checked: boolean): void {
    updateSetting({
      id: "new-subscriptions",
      label: "Novas assinaturas",
      value: checked,
    });
  }

  function updateSetting(updatedSetting: ISetting): void {
    update("settings", updatedSetting.id, updatedSetting);
    const settingIndex = settings.findIndex(
      (setting) => setting.id === updatedSetting.id
    );
    const updatedSettings = [...settings];
    updatedSettings[settingIndex] = updatedSetting;
    setSettings(updatedSettings);
  }

  function isSettingChecked(settingId: string): boolean {
    return settings.find((setting) => setting.id === settingId)?.value;
  }

  return (
    <div>
      {!isLoading ? (
        <Grow in>
          <Paper className={classes.settingItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={isSettingChecked("new-subscriptions")}
                  onChange={(event) =>
                    updateNewSubscriptionsMetric(event.target.checked)
                  }
                />
              }
              label="Permitir novas assinaturas"
            />
          </Paper>
        </Grow>
      ) : null}
    </div>
  );
}

export default Settings;
