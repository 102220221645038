import { CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IMonthBox } from "../../interfaces/month-boxes";
import dataService from "../../services/data-service";
import useStyles from "./styles";
import openBoxImage from "../../assets/images/current-box-image.png";
import {
  SubscriptionTypes,
  SubscriptionTypesLabel,
} from "../../enums/suscription-types";

interface MonthBoxItemProps {
  month: number;
  type: SubscriptionTypes;
  total: number;
}

function MonthBoxItem(props: MonthBoxItemProps) {
  const classes = useStyles();

  return (
    <div className={classes.monthBoxItem}>
      <img src={openBoxImage} alt="Box" className={classes.boxImage} />
      <div className={classes.totalContainer}>
        <Typography className={classes.totalText}>{props.total}</Typography>
      </div>
      <div className={classes.boxDetailsArea}>
        <Typography className={classes.boxMonthText}>
          Box {props.month}
        </Typography>
        <Typography className={classes.boxTypeText}>
          {/* @ts-expect-error */}
          {SubscriptionTypesLabel[SubscriptionTypes[props.type]]}
        </Typography>
      </div>
    </div>
  );
}

function Inventory() {
  const classes = useStyles();

  const [monthBoxes, setMonthBoxes] = useState({} as IMonthBox);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchMonthBoxes(): Promise<void> {
      setIsLoading(true);
      const monthBoxes = await dataService.getMonthBoxes();
      setMonthBoxes(monthBoxes);
      setIsLoading(false);
    }

    fetchMonthBoxes();
  }, []);
  return !isLoading && Object.keys(monthBoxes).length ? (
    <div className={classes.inventory}>
      <Typography variant="h5" className={classes.totalBoxesText}>
        {monthBoxes.total} Boxes para enviar neste mês
      </Typography>
      {[
        SubscriptionTypes[SubscriptionTypes.BASIC],
        SubscriptionTypes[SubscriptionTypes.COMPLETE],
      ].map((subscriptionType) => (
        <div className={classes.boxesArea}>
          {/* @ts-expect-error */}
          {Object.keys(monthBoxes[subscriptionType]).map((month) =>
            //@ts-expect-error
            monthBoxes[subscriptionType][month] ? (
              <MonthBoxItem
                month={parseInt(month)}
                //@ts-expect-error
                type={SubscriptionTypes[subscriptionType]}
                //@ts-expect-error
                total={monthBoxes[subscriptionType][month]}
              />
            ) : null
          )}
        </div>
      ))}
    </div>
  ) : (
    <div className={classes.loadingIndicatorArea}>
      <CircularProgress />
    </div>
  );
}

export default Inventory;
