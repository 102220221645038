import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  deleteSubscriptionModal: {},
  dialogContent: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
  },
  contentArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  topArea: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeModalButton: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: "#FFF",
  },
  titleArea: {
    display: "flex",
    flexDirection: "column",
  },
  titleText: {
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  subtitleText: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginTop: 20,
  },
  buttonsArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 60,
    paddingBottom: 40,
  },
  goBackButton: {
    width: 364,
    height: 62,
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    fontWeight: 700,
    color: theme.palette.secondary.main,
    backgroundColor: "#FFF !important",
  },
  deleteSubscriptionButton: {
    width: 364,
    height: 62,
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    fontWeight: 700,
    color: "#FFF !important",
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
}));

export default useStyles;
