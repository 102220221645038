import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import {
  ChildCare as SubscriptionsIcon,
  Settings as SettingsIcon,
  ShoppingCart as InventoryIcon,
} from "@material-ui/icons";
import { ResourceComponentProps } from "ra-core";
import { ComponentType } from "react";
import SubscriptionsList from "../components/subscriptions/list/SubscriptionsList";
import SubscriptionView from "../components/subscriptions/view";
import Settings from "../components/settings";
import inventory from "../components/inventory";

export interface IResource {
  name: string;
  label: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  listComponent: ComponentType<ResourceComponentProps>;
  viewComponent?: ComponentType<ResourceComponentProps>;
}

const resources: IResource[] = [
  {
    name: "subscriptions",
    label: "Assinaturas",
    icon: SubscriptionsIcon,
    listComponent: SubscriptionsList,
    viewComponent: SubscriptionView,
  },
  //@ts-expect-error
  {
    name: "customers",
  },
  {
    name: "inventory",
    label: "Estoque",
    icon: InventoryIcon,
    listComponent: inventory,
  },
  {
    name: "settings",
    label: "Configurações",
    icon: SettingsIcon,
    listComponent: Settings,
  },
];

export default resources;
