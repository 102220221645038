import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowForwardRounded as ArrowRightIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { useRefresh, useUpdate } from "react-admin";
import { PaymentTypeLabels } from "../../../../constants/payment-types";
import {
  SubscriptionStatus,
  SubscriptionStatusLabel,
} from "../../../../enums/subscription-status";
import {
  SubscriptionTypes,
  SubscriptionTypesLabel,
} from "../../../../enums/suscription-types";
import { formatDate } from "../../../../helpers/field-format-helper";
import { ISubscription } from "../../../../interfaces/subscription";
import dataService from "../../../../services/data-service";
import EmailFeedbackModal from "./email-feedback-modal";
import useStyles from "./styles";

interface ISubscriptionActionsProps {
  subscription: ISubscription;
  subscriptionChanged: Function;
}

interface ISubscriptionStatusUpdateButtonProps {
  label: string;
  box: number;
  subscription: ISubscription;
  onClick: Function;
  disabled?: boolean;
}

function SubscriptionStatusUpdateButton(
  props: ISubscriptionStatusUpdateButtonProps
) {
  const classes = useStyles();

  function getSubscriptionType(subscription: ISubscription): string {
    // @ts-expect-error
    return SubscriptionTypesLabel[SubscriptionTypes[props.subscription.type]];
  }

  return (
    <Button
      fullWidth
      className={classes.subscriptionStatusUpdateButton}
      classes={{
        disabled: classes.disabledSubscriptionUpdateButton,
      }}
      disabled={props.disabled}
      onClick={() => props.onClick()}
    >
      <div className={classes.leftArea}>
        <Typography variant="body2" className={classes.boxLabel}>
          Box {props.box} - {getSubscriptionType(props.subscription)}
        </Typography>
        <Typography variant="h5" className={classes.label}>
          {props.label}
        </Typography>
      </div>
      <div className={classes.rightArea}>
        <ArrowRightIcon
          className={
            props.disabled
              ? classes.arrowRightIconDisabled
              : classes.arrowRightIcon
          }
        />
      </div>
    </Button>
  );
}

function SubscriptionActions(props: ISubscriptionActionsProps) {
  const classes = useStyles();
  const [update] = useUpdate();
  const refresh = useRefresh();

  const [statusUpdateEmailFeedbackOpen, setStatusUpdateEmailFeedbackOpen] =
    useState(false);
  const [newSubscriptionStatus, setNewSubscriptionStatus] = useState(
    SubscriptionStatus.SENT
  );

  const [isLoading, setIsLoading] = useState(false);

  if (!props.subscription) return null;

  async function advanceBox(subscriptionId: string): Promise<void> {
    setIsLoading(true);
    try {
      await dataService.advanceBox(subscriptionId);
      refresh();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function updateSubscriptionStatus(
    subscriptionId: string,
    status: SubscriptionStatus
  ): Promise<void> {
    if (
      status === SubscriptionStatus.SENT ||
      status === SubscriptionStatus.COMPLETED ||
      status === SubscriptionStatus.PAYMENT_REJECTED ||
      status === SubscriptionStatus.IN_PROGRESS ||
      status === SubscriptionStatus.FIRST_PAYMENT_NOT_RECEIVED
    ) {
      setStatusUpdateEmailFeedbackOpen(true);
      setNewSubscriptionStatus(status);
      return;
    }
    try {
      await dataService.updateSubscriptionStatus(subscriptionId, status);
      props.subscriptionChanged("status", status);
      refresh();
    } catch (error) {
      console.error(error);
    }
  }

  function onOldSubscriptionStatusChange(isOldSubscription: boolean): void {
    update("subscriptions", props.subscription.id, {
      ...props.subscription,
      isOldSubscription,
    });
  }

  return (
    <div className={classes.subscriptionActions}>
      <div className={classes.subscriptionStatusChangeArea}>
        <div
          style={{
            flex: 1,
          }}
        >
          <FormControl fullWidth>
            {/* <InputLabel>Box {props.subscription.currentBox}</InputLabel> */}
            <Select
              fullWidth
              style={{
                height: 60,
                width: "95%",
                marginLeft: 10,
              }}
              SelectDisplayProps={{
                style: {
                  backgroundColor: "transparent",
                },
              }}
              value={props.subscription.status}
              className={classes.subscriptionStatusButton}
              variant="outlined"
              onChange={(event) =>
                updateSubscriptionStatus(
                  `${props.subscription.id}`,
                  event.target.value as SubscriptionStatus
                )
              }
            >
              <MenuItem value={SubscriptionStatus.PENDING_PAYMENT}>
                {SubscriptionStatusLabel["PENDING_PAYMENT"]}
              </MenuItem>
              <MenuItem value={SubscriptionStatus.IN_PROGRESS}>
                {SubscriptionStatusLabel["IN_PROGRESS"]}
              </MenuItem>
              <MenuItem value={SubscriptionStatus.SENT}>
                {SubscriptionStatusLabel["SENT"]}
              </MenuItem>
              <MenuItem value={SubscriptionStatus.COMPLETED}>
                {SubscriptionStatusLabel["COMPLETED"]}
              </MenuItem>
              <MenuItem value={SubscriptionStatus.PAYMENT_REJECTED}>
                {SubscriptionStatusLabel["PAYMENT_REJECTED"]}
              </MenuItem>
              <MenuItem value={SubscriptionStatus.FIRST_PAYMENT_NOT_RECEIVED}>
                {SubscriptionStatusLabel["FIRST_PAYMENT_NOT_RECEIVED"]}
              </MenuItem>
              <MenuItem value={SubscriptionStatus.CREATE_FISCAL_NOTE}>
                {SubscriptionStatusLabel["CREATE_FISCAL_NOTE"]}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <SubscriptionStatusUpdateButton
            label="Próxima Box"
            subscription={props.subscription}
            box={props.subscription.currentBox + 1}
            onClick={() => advanceBox(`${props.subscription.id}`)}
            disabled={
              props.subscription.status !== SubscriptionStatus.COMPLETED ||
              props.subscription.currentBox === 12 ||
              isLoading
            }
          />
        </div>
      </div>
      <div className={classes.lastBoxDetailsArea}>
        <TextField
          variant="filled"
          label="Última Box"
          value={props.subscription.lastBox || "-"}
          disabled
          fullWidth
          className={classes.subscriptionViewField}
          InputProps={{
            className: classes.inputText,
          }}
        />
        <TextField
          variant="filled"
          label="Data de envio"
          value={formatDate(props?.subscription?.lastBoxSentAt)}
          disabled
          fullWidth
          className={classes.subscriptionViewField}
          InputProps={{
            className: classes.inputText,
          }}
        />
      </div>
      <TextField
          disabled
          variant="filled"
          label="Pagamento"
          value={PaymentTypeLabels[props?.subscription?.paymentType] || "Recorrência"}
          className={classes.subscriptionViewField}
          InputProps={{
            className: classes.inputText,
          }}
        />
      <div className={classes.oldSubscriptionSelectorArea}>
        <FormControlLabel
          control={
            <Switch
              checked={props.subscription.isOldSubscription}
              onChange={(event) =>
                onOldSubscriptionStatusChange(event.currentTarget.checked)
              }
            />
          }
          label="Assinatura antiga"
        />
      </div>
      <EmailFeedbackModal
        open={statusUpdateEmailFeedbackOpen}
        onClose={() => setStatusUpdateEmailFeedbackOpen(false)}
        status={newSubscriptionStatus}
        subscription={props.subscription}
        subscriptionChanged={(field: string, value: string) =>
          props.subscriptionChanged(field, value)
        }
      />
    </div>
  );
}

export default SubscriptionActions;
