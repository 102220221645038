import { createMuiTheme } from "@material-ui/core";
import { defaultTheme } from "react-admin";

const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: "#58BEBE",
    },
    secondary: {
      main: "#E94A69",
    },
    text: {
      primary: "#7C8999",
      secondary: "#BCBBC4",
    },
    background: {
      default: "#F8F9F9",
    },
  },
  typography: {
    fontFamily: "Dosis, Roboto, Bakso Sapi, Frenchpress",
    h1: {
      WebkitTextStroke: "1px",
    },
    h2: {
      WebkitTextStroke: "1px",
    },
  },

  overrides: {
    MuiButton: {
      contained: {
        height: 42,
        borderRadius: 42,
        color: "#FFF",
        fontWeight: 800,
        fontFamily: "Dosis",
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: "#FFF !important",
        borderRadius: 20,
      },
      input: {
        "&::placeholder": {
          color: "#BCBBC4",
          fontWeight: 500,
          fontSize: 20,
          fontFamily: "Roboto",
        },
        paddingLeft: 20,
        color: "#7C8999",
      },
      notchedOutline: {
        border: "2px solid #BCBBC4",
      },
      error: {
        borderColor: "red !important",
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiTextField: {
      InputProps: {
        disableUnderline: true,
      },
    },
  },
});

export default theme;
