import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 50,
    overflow: "hidden",
  },
}));

export default useStyles;
