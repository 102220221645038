import { differenceInMinutes } from "date-fns";

class CacheService {
  cache: any;
  constructor() {
    this.cache = {};
  }

  setCachedList(resource: string, list: any[]): void {
    this.cache[resource] = {
      items: list,
      cachedAt: new Date(),
    };
  }

  getCachedList(resource: string): any[] {
    return this.cache[resource]?.items || [];
  }

  isCachedList(resource: string): boolean {
    if (!this.cache[resource]) return false;
    return differenceInMinutes(this.cache[resource].cachedAt, new Date()) <= 5;
  }

  getCachedItem(resource: string, id: string) {
    if (!this.cache[resource]) return null;
    return this.cache[resource].items.find((item: any) => item.id === id);
  }
  setCachedItem(resource: string, id: string, data: any) {
    if (!this.cache[resource]) return null;
    const itemIndex = this.cache[resource].items.findIndex(
      (item: any) => item.id === id
    );

    if (itemIndex !== -1) {
      this.cache[resource].items[itemIndex] = data;
    }
  }

  removeCachedItem(resource: string, id: string): void {
    this.cache[resource].items = this.cache[resource].items.filter(
      (item: any) => item.id !== id
    );
  }
}

export default new CacheService();
