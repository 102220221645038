import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  settingItem: {
    padding: 16,
    borderRadius: 8,
  },
}));

export default useStyles;
