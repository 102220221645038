import {
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { SubscriptionStatus } from "../../enums/subscription-status";
import { sortData } from "../../helpers/sort-data";
import { IMonthBox } from "../../interfaces/month-boxes";
import { ISetting } from "../../interfaces/setting";
import { ISubscription } from "../../interfaces/subscription";
import authService from "../auth-service";
import cacheService from "../cache-service";

class DataService {
  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    let data;
    if (!cacheService.isCachedList(resource)) {
      const authorizationToken = await authService.getUserToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${resource}`,
        {
          headers: {
            Authorization: `Bearer ${authorizationToken}`,
          },
        }
      );
      data = await response.json();
      cacheService.setCachedList(resource, data);
    } else {
      data = cacheService.getCachedList(resource);
    }

    if (Object.keys(params.filter).length) {
      if (params.filter.textFilter) {
        const textFilter = params.filter.textFilter;
        data = data.filter(
          (d: ISubscription) =>
            d.subscriptionNumber === parseInt(textFilter) ||
            d.customer.name.toLowerCase().includes(textFilter.toLowerCase())
        );
      }
      if (params.filter.statusFilter) {
        data = data.filter(
          (d: ISubscription) =>
            d.status === (SubscriptionStatus[params.filter.statusFilter] as any)
        );
      }
    }

    if (params.sort?.field && params.sort?.order) {
      data = sortData(data, params.sort.field, params.sort.order);
    }

    const total = data.length;

    if (params?.pagination) {
      data = data.slice(
        (params.pagination.page - 1) * params.pagination.perPage,
        params.pagination.perPage * params.pagination.page
      );
    }

    return {
      data,
      total,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    const authorizationToken = await authService.getUserToken();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/${resource}/${params.id}`,
      {
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
      }
    );
    const data = await response.json();

    return {
      data,
    };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult> {
    const authorizationToken = await authService.getUserToken();
    const body: any = {
      ids: params.ids,
    };
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/${resource}/ids`,
      {
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    const data = await response.json();

    return {
      data,
    };
  }

  async update(resource: string, params: UpdateParams): Promise<UpdateResult> {
    const authorizationToken = await authService.getUserToken();
    const request = await fetch(
      `${process.env.REACT_APP_BASE_URL}/${resource}/${params.id}`,
      {
        method: "PUT",
        body: JSON.stringify(params.data),
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
      }
    );

    const data = await request.json();

    cacheService.setCachedItem(resource, data.id, data);

    return {
      data,
    };
  }
  async getManyReference() {}
  async create() {}
  async updateMany() {}
  async delete(resource: string, params: DeleteParams): Promise<DeleteResult> {
    const authorizationToken = await authService.getUserToken();
    const request = await fetch(
      `${process.env.REACT_APP_BASE_URL}/${resource}/${params.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
      }
    );

    cacheService.removeCachedItem(resource, params.id as any);

    return {
      data: await request.json(),
    };
  }
  async deleteMany() {}

  async updateSubscriptionStatus(
    subscriptionId: string,
    status: SubscriptionStatus,
    sendEmail = false,
    emailContent = {}
  ): Promise<ISubscription> {
    const authorizationToken = await authService.getUserToken();

    const request = await fetch(
      `${process.env.REACT_APP_BASE_URL}/subscriptions/${subscriptionId}/status/${status}`,
      {
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
        method: "POST",
        body: JSON.stringify({
          sendEmail,
          emailContent,
        }),
      }
    );

    const data = await request.json();

    cacheService.setCachedItem("subscriptions", data.id, data);

    return data;
  }

  async advanceBox(subscriptionId: string): Promise<ISubscription> {
    const authorizationToken = await authService.getUserToken();

    const request = await fetch(
      `${process.env.REACT_APP_BASE_URL}/subscriptions/${subscriptionId}/advance-box`,
      {
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
        method: "POST",
      }
    );

    const data = await request.json();

    cacheService.setCachedItem("subscriptions", data.id, data);

    return data;
  }

  public async getMetrics(): Promise<any> {
    const authorizationToken = await authService.getUserToken();

    const request = await fetch(`${process.env.REACT_APP_BASE_URL}/metrics`, {
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
      },
    });

    return await request.json();
  }

  public async getSettings(): Promise<ISetting[]> {
    const authorizationToken = await authService.getUserToken();
    const request = await fetch(`${process.env.REACT_APP_BASE_URL}/settings`, {
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
      },
    });

    return await request.json();
  }

  public async getWeekSubscriptions(): Promise<ISubscription[]> {
    const authorizationToken = await authService.getUserToken();
    const request = await fetch(
      `${process.env.REACT_APP_BASE_URL}/subscriptions/week-subscriptions`,
      {
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
      }
    );

    return await request.json();
  }

  public async getNewSubscriptions(): Promise<ISubscription[]> {
    const authorizationToken = await authService.getUserToken();
    const request = await fetch(
      `${process.env.REACT_APP_BASE_URL}/subscriptions/new-subscriptions`,
      {
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
      }
    );

    return await request.json();
  }

  public async getMonthBoxes(): Promise<IMonthBox> {
    const authorizationToken = await authService.getUserToken();

    const request = await fetch(
      `${process.env.REACT_APP_BASE_URL}/subscriptions/month-boxes`,
      {
        headers: {
          Authorization: `Bearer ${authorizationToken}`,
        },
      }
    );

    return await request.json();
  }
}

export default new DataService();
