enum SubscriptionTypes {
  BASIC = 0,
  COMPLETE,
}

enum SubscriptionTypesLabel {
  BASIC = "Basica",
  COMPLETE = "Completa",
}

export { SubscriptionTypes, SubscriptionTypesLabel };
