import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import {
  ShowProps,
  useRefresh,
  useShowController,
  useUpdate,
} from "react-admin";
import currentBoxImage from "../../../assets/images/current-box-image.png";
import notSentBoxImage from "../../../assets/images/not-sent-box-image.png";
import sentBoxImage from "../../../assets/images/sent-box-image.png";
import { ISubscription } from "../../../interfaces/subscription";
import DeleteSubscriptionModal from "./delete-subscription-modal";
import useStyles from "./styles";
import SubscriptionActions from "./subscription-actions";
import classNames from "classnames";
import ptBrLocale from "date-fns/locale/pt-BR";
import { ICustomer } from "../../../interfaces/customer";
import dataService from "../../../services/data-service";
interface IBoxItemProps {
  subscription: ISubscription;
  sent: boolean;
  isCurrentBox: boolean;
  month: number;
}

function BoxItem(props: IBoxItemProps) {
  const classes = useStyles();

  function getBoxImage() {
    if (props.isCurrentBox) return currentBoxImage;
    if (props.sent) return sentBoxImage;
    return notSentBoxImage;
  }

  return (
    <div className={classes.boxItem}>
      {props.month === 9 && props.subscription.isOldSubscription ? (
        <span className={classes.oldBoxText}>*Box antiga</span>
      ) : null}
      <img src={getBoxImage()} alt="Box" />
    </div>
  );
}

function SubscriptionView(props: ShowProps) {
  const classes = useStyles();
  const { record, loading } = useShowController(props);
  const [update] = useUpdate();
  const refresh = useRefresh();

  const [editingMode, setEditingMode] = useState(false);
  const [customer, setCustomer] = useState({} as ICustomer);
  const [address, setAddress] = useState({} as ICustomer["address"]);
  const [deleteSubscriptionModalOpen, setDeleteSubscriptionModalOpen] =
    useState(false);
  const [subscription, setSubscription] = useState(record as ISubscription);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (record) {
      setIsLoading(true);
      fetchCustomer(record.customerId as string);
    }
  }, [record]);

  async function fetchCustomer(customerId: string): Promise<void> {
    const { data } = await dataService.getOne("customers", {
      id: customerId,
    });
    setCustomer(data as ICustomer);
    setAddress(data.address as ICustomer["address"]);
    setIsLoading(false);
  }

  function onCustomerDetailChange(key: string, value: any): void {
    setCustomer({
      ...customer,
      [key]: value,
    });
  }

  function onCustomerAddressChange(key: string, value: any): void {
    setAddress({
      ...address,
      [key]: value,
    });
  }

  function onSubscriptionChange(field: string, value: string): void {
    setSubscription({
      ...subscription,
      [field]: value,
    });
  }

  function onCancelSubscriptionEdition(): void {
    setCustomer(record?.customer);
    setAddress(record?.customer?.address);
    setSubscription(record as ISubscription);
    setEditingMode(false);
  }

  function updateData(): void {
    const updatedCustomer = {
      ...customer,
      address,
    };
    if (
      record?.notes !== subscription?.notes ||
      record?.activedAt !== subscription.activedAt ||
      record?.howFoundUs !== subscription.howFoundUs
    ) {
      updateSubscription();
    }
    update("customers", customer?.id, updatedCustomer);
    refresh();
    setEditingMode(false);
  }

  function updateSubscription(): void {
    const { customer, ...updatedSubscription } = subscription;
    update("subscriptions", updatedSubscription.id, updatedSubscription);
  }

  return loading || isLoading ? (
    <div className={classes.progressIndicatorArea}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <div className={classes.subscriptionView}>
        <div className={classes.boxListArea}>
          {Array.from(Array(12).keys()).map((_month, index) => (
            <BoxItem
              subscription={record as ISubscription}
              sent={
                index + 1 <= record?.lastBox && index + 1 >= record?.initialBox
              }
              isCurrentBox={index + 1 === record?.currentBox}
              month={index + 1}
            />
          ))}
        </div>
        <div className={classes.subscriptionDetailsArea}>
          <div className={classes.leftArea}>
            <div className={classes.subscriptionArea}>
              <Typography
                variant="h5"
                className={classes.customerInfoContainerTitleText}
              >
                Assinatura
              </Typography>
              {subscription?.id ? (
                <SubscriptionActions
                  subscription={record as ISubscription}
                  subscriptionChanged={(field: string, value: string) =>
                    onSubscriptionChange(field, value)
                  }
                />
              ) : null}
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                <DatePicker
                  format="dd/MM/yyyy"
                  label="Data de Criação"
                  value={
                    subscription?.activedAt
                      ? subscription?.activedAt
                      : subscription?.createdAt
                  }
                  className={classNames(classes.subscriptionViewField)}
                  InputProps={{
                    className: classes.inputText,
                    style: {
                      padding: 10,
                    },
                  }}
                  inputProps={{}}
                  InputLabelProps={{
                    style: {
                      padding: 16,
                    },
                  }}
                  disabled={!editingMode}
                  onChange={(updatedDate) =>
                    onSubscriptionChange(
                      "activedAt",
                      new Date(updatedDate as any).toISOString()
                    )
                  }
                />
              </MuiPickersUtilsProvider>
              <TextField
                placeholder="Observações"
                multiline
                className={classes.subscriptionViewField}
                minRows={4}
                maxRows={4}
                value={subscription?.notes || ""}
                disabled={!editingMode}
                InputProps={{
                  className: classes.subscriptionNotesText,
                }}
                onChange={(event) =>
                  onSubscriptionChange("notes", event.target.value)
                }
              />
            </div>
            <div className={classes.customerAddressContainer}>
              <Typography
                variant="h5"
                className={classes.customerInfoContainerTitleText}
              >
                Endereço
              </Typography>
              <TextField
                variant="filled"
                label="CEP"
                value={address?.zipCode}
                disabled={!editingMode}
                className={classes.subscriptionViewField}
                InputProps={{
                  className: classes.inputText,
                }}
                onChange={(event) =>
                  onCustomerAddressChange("zipCode", event.target.value)
                }
              />
              <div className={classes.row}>
                <TextField
                  variant="filled"
                  label="Cidade"
                  value={address?.city}
                  disabled={!editingMode}
                  className={classes.subscriptionViewField}
                  InputProps={{
                    className: classes.inputText,
                  }}
                  onChange={(event) =>
                    onCustomerAddressChange("city", event.target.value)
                  }
                />
                <TextField
                  variant="filled"
                  label="Estado"
                  value={address?.state}
                  disabled={!editingMode}
                  className={classes.subscriptionViewField}
                  InputProps={{
                    className: classes.inputText,
                  }}
                  onChange={(event) =>
                    onCustomerAddressChange("state", event.target.value)
                  }
                />
              </div>

              <TextField
                variant="filled"
                label="Bairro"
                value={address?.neighborhood}
                disabled={!editingMode}
                className={classes.subscriptionViewField}
                InputProps={{
                  className: classes.inputText,
                }}
                onChange={(event) =>
                  onCustomerAddressChange("neighborhood", event.target.value)
                }
              />
              <TextField
                variant="filled"
                label="Rua"
                value={address?.street}
                disabled={!editingMode}
                className={classes.subscriptionViewField}
                InputProps={{
                  className: classes.inputText,
                }}
                onChange={(event) =>
                  onCustomerAddressChange("street", event.target.value)
                }
              />
              <div className={classes.row}>
                <TextField
                  variant="filled"
                  label="Numero"
                  value={address?.number}
                  disabled={!editingMode}
                  className={classes.subscriptionViewField}
                  InputProps={{
                    className: classes.inputText,
                  }}
                  onChange={(event) =>
                    onCustomerAddressChange("number", event.target.value)
                  }
                />
                <TextField
                  variant="filled"
                  label="Complemento"
                  value={address?.complement}
                  disabled={!editingMode}
                  className={classes.subscriptionViewField}
                  InputProps={{
                    className: classes.inputText,
                  }}
                  onChange={(event) =>
                    onCustomerAddressChange("complement", event.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.customerInfoContainer}>
            <Typography
              variant="h5"
              className={classes.customerInfoContainerTitleText}
            >
              Identidade
            </Typography>
            <TextField
              variant="filled"
              label="Name"
              value={customer?.name}
              disabled={!editingMode}
              className={classes.subscriptionViewField}
              InputProps={{
                className: classes.inputText,
              }}
              onChange={(event) =>
                onCustomerDetailChange("name", event.target.value)
              }
            />
            <TextField
              variant="filled"
              label="CPF"
              value={customer?.cpf}
              disabled={!editingMode}
              className={classes.subscriptionViewField}
              InputProps={{
                className: classes.inputText,
              }}
              onChange={(event) =>
                onCustomerDetailChange("cpf", event.target.value)
              }
            />
            <TextField
              variant="filled"
              label="E-mail"
              value={customer?.email}
              disabled={!editingMode}
              className={classes.subscriptionViewField}
              InputProps={{
                className: classes.inputText,
              }}
              onChange={(event) =>
                onCustomerDetailChange("email", event.target.value)
              }
            />
            <TextField
              variant="filled"
              label="Telefone"
              value={customer?.phoneNumber}
              disabled={!editingMode}
              className={classes.subscriptionViewField}
              InputProps={{
                className: classes.inputText,
              }}
              onChange={(event) =>
                onCustomerDetailChange("phoneNumber", event.target.value)
              }
            />
            <TextField
              variant="filled"
              label="Nome do Bebê"
              value={customer?.babyName}
              disabled={!editingMode}
              className={classes.subscriptionViewField}
              InputProps={{
                className: classes.inputText,
              }}
              onChange={(event) =>
                onCustomerDetailChange("babyName", event.target.value)
              }
            />
            <TextField
              variant="filled"
              label="Data de Nascimento"
              value={customer?.babyBirthDate}
              disabled={!editingMode}
              className={classes.subscriptionViewField}
              InputProps={{
                className: classes.inputText,
              }}
              onChange={(event) =>
                onCustomerDetailChange("babyBirthDate", event.target.value)
              }
            />
            <TextField
              variant="filled"
              multiline
              rows={2}
              label="Como nos conheceu"
              value={subscription?.howFoundUs}
              disabled={!editingMode}
              className={classes.subscriptionViewField}
              InputProps={{
                className: classes.inputText,
              }}
              onChange={(event) =>
                onSubscriptionChange("howFoundUs", event.target.value)
              }
            />
          </div>
        </div>
        <div className={classes.actionButtonsArea}>
          {!editingMode ? (
            <>
              <Button
                variant="contained"
                onClick={() => setDeleteSubscriptionModalOpen(true)}
                className={classes.deleteSubscriptionButton}
              >
                CANCELAR ASSINATURA
              </Button>
              <Button
                variant="contained"
                onClick={() => setEditingMode(true)}
                className={classes.enableEditingButton}
              >
                EDITAR INFORMAÇÕES
              </Button>
            </>
          ) : (
            <div>
              <Button
                variant="contained"
                className={classes.cancelButton}
                onClick={onCancelSubscriptionEdition}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                className={classes.saveButton}
                onClick={updateData}
              >
                Salvar
              </Button>
            </div>
          )}
        </div>
      </div>
      <DeleteSubscriptionModal
        open={deleteSubscriptionModalOpen}
        onClose={() => setDeleteSubscriptionModalOpen(false)}
        subscription={record as ISubscription}
      />
    </>
  );
}

export default SubscriptionView;
