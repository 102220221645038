import { Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

interface IMetricItemProps {
  label: string;
  value: string;
  icon: any;
}

function MetricItem(props: IMetricItemProps) {
  const classes = useStyles();
  const Icon = props.icon;
  return (
    <div className={classes.metricItem}>
      <div className={classes.iconArea}>
        <Icon />
      </div>
      <div className={classes.detailsArea}>
        <Typography className={classes.labelText} variant="body1">
          {props.label}
        </Typography>
        <Typography className={classes.valueText} variant="h5">
          {props.value}
        </Typography>
      </div>
    </div>
  );
}

export default MetricItem;
