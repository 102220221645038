import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  emailFeedbackModalContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 20,
  },
  topArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  contentArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 4,
  },
  titleText: {
    color: "#57BEBF",
    fontWeight: 700,
  },
  emailText: {
    color: "#7C8999",
    fontWeight: 500,
    marginTop: 16,
    fontFamily: "Roboto, sans-serif",
  },
  emailContentArea: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
  },
  actionButtonsArea: {
    display: "flex",
    alignItems: "center",
    marginTop: 40,
    marginBottom: 40,
  },
  confirmWithoutEmailButton: {
    width: 250,
    backgroundColor: "#7C8999 !important",
    color: "#FFF !important",
  },
  confirmAndSendEmailButton: {
    width: 250,
    backgroundColor: "#57BEBF !important",
    color: "#FFF !important",
    marginLeft: 10,
  },
  trackingCodeInput: {
    marginTop: 32,
    marginBottom: 16,
  },
  buttonLoadingIndicator: {
    marginLeft: 20,
    position: "absolute",
    color: "#FFF",
  },
  emailButtonArea: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
    marginBottom: 24,
  },
  paymentButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFF",
    width: 420,
    height: 60,
    borderRadius: 60,
    fontSize: 18,
    fontWeight: 700,
  },
}));

export default useStyles;
