import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  metricsArea: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  subscriptionsListArea: {
    flex: 1,
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: 50,
  },
}));

export default useStyles;
